.scrollToTop {
    display: none !important;
}

a[href=""]:after {
    content: '';
}

.label {
    border: none;
}

.page-header-after, .page-footer-before, .page-footer {
    display: none;
}

.top-search-wrapper, .top-links-wrapper {
    display: none;
}

.navbar-brand {
    margin: 0 0 20px 15px;
}

.social-items-container {
    display: none;
}

.items-qty {
    padding-left: 0;
    .item {
        list-style-type: none;
    }
}

.customer-account-forgotpassword #maincontent, .customer-account-createpassword #maincontent, .b2b-portal-login #maincontent, .customer-account-create #maincontent, .b2b-portal-register #maincontent, .account #maincontent, .customer-account-logoutsuccess #maincontent {
    margin-top: 15px;
    margin-bottom: 15px;
}

.account .column.main .block:not(.widget) {
    margin-bottom: 0;
}

#maincontent .page-title-wrapper h1.page-title {
    font-size: 36px;
    border-bottom-color: #000;
}

.order-status:before {
    content: 'Order Status: ';
    display: block;
    margin-bottom: 3px;
    font-weight: 700;
}

.order-date {
    .label {
        font-weight: 700;
    }
}

.table-wrapper.order-items {
    margin: 20px 0 0;
}

.footer-strap-line img {
    width: 500px; 
}